<template>
  <div class="item_question col-12">
    <div class="box_question_example custom_form_question">
      <div
        :id="'heading_question_' + question.id"
        class="heading_question"
      >
        <span
          v-if="typeof question.stt !== 'undefined'"
          class="number_quiz"
        >{{ question.stt }}</span>
        <span
          v-else
          class="number_quiz"
        >{{ stt }}</span>
        <div class="text-content">
<!--          <p v-html="question.name" />-->
          <div
            v-if="question.explain"
            class="transcriptGroup"
          >
            <div
              class="transcriptGroup_title"
              :class="transcriptShow ? 'actived' : ''"
            >
              <span @click="transcriptShow =! transcriptShow">Giải thích</span>
            </div>
            <div
              v-if="transcriptShow"
              class="transcriptGroup_body"
            >
              <p v-html="question.explain" />
            </div>
          </div>
          <div class="transcriptGroup">
            <div
              class="transcriptGroup_title"
              :class="showAns ? 'actived' : ''"
            >
              <span @click="showAns =! showAns">Đáp án</span>
            </div>
            <div
              v-if="showAns"
              class="transcriptGroup_body"
            >
              <p>
                <span v-for="(word,index) in answerCorrect"> {{ word.q }} </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="compound-word-group"
      >
        <div
          v-if="answeredData"
          class="compound-word-group_oder"
        >
          <div
            v-for="(word,index) in answeredData"
            :id="'block__click__item-'+word.id"
            :key="index"
            class="compound-word-group_oder-item"
          >
            <div
              :id="'block__answer__item-'+word.id"
              class="compound-word-group_key-item-key"
              :class="success ? 'success-answer' : 'append__item'"
            >
              {{ word.q }}
            </div>
          </div>
        </div>
        <div v-else>
          <span>Không có dữ liệu sắp xếp</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    answered: {
      type: Array,
      required: false,
    },
    stt: {
      default: false,
    },
    qgrId: {
      required: false,
    },
  },
  data() {
    return {
      transcriptShow: false,
      showAns: false,
      answerCorrect: null,
      success: false,
      answers: [],
      answeredData: null,
    }
  },
  created() {
    if (this.answered) {
      const ans = this.answered.find(ans => {
        if (ans.qId === this.question.id) {
          return ans
        }
      })
      this.success = ans ? ans.point : 0
      this.answeredData = ans ? ans.data : null
      this.answerCorrect = JSON.parse(this.question.name)
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.compound-word-group_oder {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  justify-content: center;

  &-item {
    border-radius: 6px;
    border: 1px solid #00000024;
    background: #FFF;
    width: 188px;
    height: 52px;
    flex-shrink: 0;
  }
  .append__item {
    background: #fd7e1e;
    color: #fff;
  }
  .success-answer {
    background: #34A853;
    color: #fff;
  }
}

.compound-word-group_key-item-key {
  border-radius: 6px;
  border: 1px solid #b9b9b9;
  background: #d1d1d1;
  flex-shrink: 0;
  color: #000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
