<template>
  <b-row>
    <b-col md="12">
      <b-card-code :title="lesson ? lesson.name : ''">
        <div
          v-for="(q,key) in listQuestion"
          :key="key"
          class="item_question col-12 Result_Quiz_Test"
        >
          <div class="box_question_example custom_form_question">
            <div
              :id="'heading_question_' + q.id"
              class="heading_question"
            >
              <div class="text-content">
                <p v-html="q.name" />
              </div>
            </div>
            <div
              v-if="q.images"
              class="list-image"
            >
              <template v-for="(image, key) in q.images">
                <div
                  :key="key"
                  class="image-list"
                  data-fancybox="gallery"
                  :data-src="image.path"
                  :data-caption="q.name"
                >
                  <img
                    :src="image.path"
                    style="width: 100%"
                    alt=""
                  >
                </div>
              </template>
            </div>
            <p v-if="q.audio">
              <audio
                class="mt-2"
                controls
                style="width: 100%;"
              >
                <source
                  :src="storageUrl + q.audio.url"
                  type="audio/ogg"
                >
              </audio>
            </p>
            <!--                      child-question-->
            <template v-if="q.questions">
              <div
                v-for="(childQ, keyChild) in q.questions"
                :key="keyChild"
                class="item_question__child"
              >
                <Recording
                  v-if="childQ.type === 2"
                  :question="childQ"
                  :stt="childQ.stt"
                  :answers="Object.values(answers)"
                />
                <compound-word
                  v-else-if="childQ.type === 3"
                  :question="childQ"
                  :stt="childQ.stt"
                  :answered="Object.values(answers)"
                />
                <only-question
                  v-else-if="childQ.type === 1"
                  :question="childQ"
                  :answers="Object.values(answers)"
                />
                <fill-word
                  v-else-if="childQ.type === 5"
                  :question="childQ"
                  :stt="childQ.stt"
                  :answered="Object.values(answers)"
                />
                <column-merge
                  v-else-if="childQ.type === 4"
                  :question="childQ"
                  :stt="childQ.stt"
                  :answered="Object.values(answers)"
                />
              </div>
            </template>
          </div>
        </div>
      </b-card-code>
    </b-col>
  </b-row>
</template>
<script>
import Recording from '@/views/library/Recording'
import CompoundWord from '@/views/library/CompoundWord'
import OnlyQuestion from '@/views/library/OnlyQuestion'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import FillWord from '@/views/library/FillWord'
import ColumnMerge from '@/views/library/ColumnMerge'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BEmbed,
  BTab,
  BTabs,
  BCardText, VBTooltip,
  BSkeleton,
} from 'bootstrap-vue'

export default {
  components: {
    Recording,
    OnlyQuestion,
    CompoundWord,
    FillWord,
    ColumnMerge,
    BSkeleton,
    BCardCode,
    BCardText,
    BImg,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
    BTab,
    BTabs,
  },
  data() {
    return {
      answers: null,
      listQuestion: null,
      timeEnd: null,
      lessonId: null,
      questionListActive: null,
      lesson: null,
    }
  },
  created() {
    if (this.$route.params.data) {
      const detail = this.$route.params.data
      console.log(detail)
      this.listQuestion = JSON.parse(detail.question_data)
      this.answers = JSON.parse(detail.answers)
      this.timeEnd = detail.time_remain / 1000
      this.lessonId = detail.lesson_id
      this.questionListActive = JSON.parse(detail.question_complete)
      this.lesson = detail.lesson ? detail.lesson : ''
    }
  },
  methods: {},

}
</script>
<style lang="scss" scoped>
.list-image{
  .image-list {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}
</style>
