<template>
  <div class="item_question col-12">
    <div class="heading_question">
      <span class="number_quiz">{{ stt }}</span>
    </div>
    <div
      class="transcriptGroup"
    >
      <div
        class="transcriptGroup_title"
        :class="showAns ? 'actived' : ''"
      >
        <span @click="showAns =! showAns">Đáp án</span>
      </div>
      <div
        v-if="showAns"
        class="transcriptGroup_body"
      >
        <div
          v-for="(q,index) in questions"
          class="d-flex"
        >
          <span v-html="q.name" /> - <span v-html="q.is_correct" />
          <hr>
        </div>
      </div>
    </div>
    <div
      class="question-drag-component"
    >
      <div
        id="question-drag-component"
        class="question-drag-component_col"
      >
        <div
          v-for="(qu,index) in questions"
          class="question-drag-component_row"
        >
          <div class="question-drag-component_row-item itemQuestion">
            <span v-html="qu.name" />
          </div>
          <div
            v-show="worked.length"
            class="checkResult"
            :style="'left:' + width + 'px'"
          >
            <div
              class="itemAnswers"
              :class="worked[index] == qu.is_correct ? '' : 'false-question'"
            >
              <span v-html="worked[index]" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="!worked.length"
        class="question-drag-component_col"
      >
        <div
          v-for="(ans, index) in answers"
          class="question-drag-component_row"
        >
          <div class="itemAnswers">
            <span v-html="ans" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    answered: {
      type: Array,
      required: false,
    },
    stt: {
      default: false,
    },
    qgrId: {
      required: false,
    },

  },
  data() {
    return {
      index: null,
      questions: [],
      showAns: false,
      answers: [],
      worked: [],
      work: [],
      width: 0,
    }
  },
  mounted() {
    const elements = document.getElementsByClassName('itemQuestion')
    if (elements.length) {
      this.width = elements[0].offsetWidth
    }
  },
  created() {
    if (this.question) {
      const answerData = []
      const data = JSON.parse(this.question.name)
      data.map(v => {
        this.questions.push({ name: v.q, is_correct: v.ans })
        answerData.push(v.ans)
      })
      this.answers = _.shuffle(answerData)
    }
    if (this.answered) {
      const ans = this.answered.find(ans => {
        if (ans.qId === this.question.id) {
          return ans
        }
      })
      this.worked = ans ? ans.work : []
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.false-question{
  background: #c30000 !important;
  color: #fff;
}
.question-drag-component{
  display: flex;
  gap: 100px;
}
.question-drag-component_col{
  width: 50%;
}
.question-drag-component_row{
  position: relative;
  margin: 15px 0;
}
.hide{
  opacity: 0;
}
.question-drag-component_row-item{
  text-align: center;
  width: 100%;
  background: #fd7e1e;
  border-radius: 15px;
  color: #ffffff;
  height: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-size: 15px;
  position: relative;
  z-index: 2;
}
.itemAnswers{
  text-align: center;
  width: 100%;
  background: #3eaf43;
  color: #ffffff;
  border-radius: 15px;
  height: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-size: 15px;
  position: relative;
  z-index: 2;
}

.question-drag-component_row-item.itemQuestion{
  z-index: 2;
}

.itemAnswers{
  position: sticky;
  z-index: 1;
  display: flex;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  cursor: pointer;
  transition: all 1s linear;
}
.question-drag-component_row-item.itemQuestion:before{
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fd7e1e;
  right: -15px;
}

.itemAnswers:before{
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #ffffff;
  left: -25px;
}

.question-drag-component_row.success .question-drag-component_row-item{
  background: #3eaf43;
  color: #fff;
}

.question-drag-component_row.false .question-drag-component_row-item{
  background: #c30000;
  color: #fff;
}

.question-drag-component_row.false .question-drag-component_row-item.itemQuestion:before{
  background: #c30000;
}

.question-drag-component_row.success .question-drag-component_row-item.itemQuestion:before{
  background: #3eaf43;
}

.question-drag-component_row .checkResult{
  position: absolute;
  background: transparent;
  width: 100%;
  height: 70px;
  z-index: 1;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 100px;
  /*right: -30px;*/
}

.question-drag-component_row.finishQuestion .checkResult{
  background: #fd7e1e;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 203%;
  color: #fff;
  right: 0;
  left: 0;
}

@media (max-width: 767px) {
  .question-drag-component_row.finishQuestion .checkResult{
    width: 100%;
  }
  .question-drag-component{
    flex-direction: column;
    gap: 10px;
  }
  .question-drag-component_row-item,
  .question-drag-component_col{
    width: 100%;
  }

  .question-drag-component_row-item.itemQuestion:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    right: unset;
    bottom: -10px;
  }

  .question-drag-component_row.finishQuestion .question-drag-component_row-item.itemQuestion:before{
    display: none;
  }
  .false{
    background: #c30000 !important;
  }

  .question-drag-component_row-item.itemAnswers[data-v-abc73a3a]:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    left: unset;
    top: -15px;
  }

  .question-drag-component_row{
    margin: 15px 0 20px;
  }
}
</style>
