<template>
  <div class="item_question col-12">
    <div class="heading_question">
      <span class="number_quiz">1</span>
      <div class="text-content">
        <p v-html="question.name" />
        <div
          v-if=" showResult && question.explain"
          class="transcriptGroup"
        >
          <div
            class="transcriptGroup_title"
            :class="transcriptShow ? 'actived' : ''"
          >
            <span @click="transcriptShow =! transcriptShow">Giải thích</span>
          </div>
          <div
            v-if="transcriptShow"
            class="transcriptGroup_body"
          >
            <p v-html="question.explain" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div
        class="card card-border"
        style="box-shadow: none;border: 1px dashed rgb(217 191 191) !important; margin-top: 20px"
      >
        <div class="card-body">
          <h5><strong>Kết quả ghi âm</strong></h5>
          <br>
          <audio
            v-if="audiomp3"
            controls
            style="width: 100%"
          >
            <source
              :src="audiomp3"
              type="video/webm"
            >
          </audio>
          <span v-else>Không có dữ liệu thu âm</span>
          <br>
          <div class="table-responsive mt-2">
            <table class="table resuilt-part-detail">
              <thead>
                <tr>
                  <th width="200">
                    Nội dung
                  </th>
                  <th width="120">
                    Điểm
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="ansComplete">
                  <tr>
                    <td>
                      <template v-for="w in handleStringToArray(ansComplete.text)">
                        <b :style="ansComplete.commonCharacters.includes(w) ? 'color:#34A853' : 'color:#FF0000'">{{ w }}</b>
                      </template>
                    </td>
                    <td>{{ ansComplete.point }}</td>
                  </tr>
                  <tr>
                    <td><b style="color:#34A853">{{ ansComplete.text }}</b></td>
                    <td>{{ ansComplete.totalPoint }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td>
                      <template v-for="w in handleStringToArray(question.name)">
                        <b style="color:#FF0000">{{ w }}</b>
                      </template>
                    </td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td><b style="color:#34A853">{{ question.name }}</b></td>
                    <td>{{ question.pivot.point }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    question: {
      type: Object,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
    },
    stt: {
      required: false,
    },
    showResult: {
      type: Boolean,
      default: false,
    },
    answers: {
      type: Array,
      required: false,
    },

  },
  data() {
    return {
      playButtonIconSrc: '/images/audio/icons/record.png',
      siriWave: null,
      isWavePlaying: false,
      // siriWaveCheck: null,
      transcriptShow: false,
      spokenText: null,
      recognition: null,
      mediaRecorder: null,
      gumStream: null,
      ansComplete: null,
      audiomp3: null,
      audio_id: null,
      path: null,
    }
  },
  mounted() {
    if (this.answers) {
      this.answers.map(ans => {
        if (ans.q_id === this.question.id) {
          // console.log(ans)
          this.audiomp3 = ans.path
          this.ansComplete = ans
        }
      })
    }
  },
  methods: {
    handleStringToArray(str) {
      return str.split('')
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
