<template>
  <div>
    <div class="item_question col-12">
      <div class="box_question_example custom_form_question">
        <div
          id="'heading_question_2"
          class="heading_question"
        >
          <span
            v-if="typeof question.stt !== 'undefined'"
            class="number_quiz"
          >{{ question.stt }}</span>
          <span
            v-else
            class="number_quiz"
          >{{ stt }}</span>
          <div class="text-content">
            <p />
            <div
              v-if="question.explain"
              class="transcriptGroup"
            >
              <div
                class="transcriptGroup_title"
                :class="transcriptShow ? 'actived' : ''"
              >
                <span @click="transcriptShow =! transcriptShow">Giải thích</span>
              </div>
              <div
                v-if="transcriptShow"
                class="transcriptGroup_body"
              >
                <p v-html="question.explain" />
              </div>
            </div>
            <div
              class="transcriptGroup"
            >
              <div
                class="transcriptGroup_title"
                :class="showAns ? 'actived' : ''"
              >
                <span @click="showAns =! showAns">Đáp án</span>
              </div>
              <div
                v-if="showAns"
                class="transcriptGroup_body"
              >
                <span
                  v-for="(word,index) in question.correct"
                  class="success"
                >
                  {{ word }},
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style="padding-left: 32px;"
          v-html="question.title"
        />
        <div />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'FillWord',
  props: {
    question: {
      type: Object,
      required: true,
    },
    answered: {
      type: Array,
      required: false,
    },
    stt: {
      required: false,
    },
    qgrId: {
      required: false,
    },
  },
  data() {
    return {
      transcriptShow: false,
      type_step: 1,
      storageUrl: process.env.STORAGE_BASE_URL,
      showAns: false,
      htmlContent: this.question.title,
    }
  },
  mounted() {
    if (this.answered) {
      const { correct } = this.question
      const valued = this.answered.find(ans => ans.qId === this.question.id)
      if (valued) {
        valued.answer.map((v, k) => {
          const elementById = document.getElementById(`answer_${this.question.id}_${k}`)
          elementById.value = v
          elementById.style.fontWeight = 'bold'
          elementById.style.color = (v === correct[k]) ? '#34A853' : '#ff7f00'
          elementById.disabled = true
          elementById.style.background = '#fff'
        })
      } else {
        correct.map((v, k) => {
          const elementById = document.getElementById(`answer_${this.question.id}_${k}`)
          elementById.disabled = true
          elementById.style.background = '#fff'
        })
      }
    }
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.success {
  color: #34A853 !important;
}

.wrong {
  color: #ff7f00 !important;
}
</style>
