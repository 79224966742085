<template>
  <div class="item_question col-12">
    <div class="box_question_example custom_form_question">
      <span
        :id="'check-correct-question-' + question.id"
        class="float-right"
      >{{ checkCorrectQuestion() }}</span>
      <div
        :id="'heading_question_' + question.id"
        class="heading_question"
      >
        <span
          v-if="typeof question.stt !== 'undefined'"
          class="number_quiz"
        >{{ question.stt }}
        </span>
        <div class="text-content">
          <p v-html="question.name" />
          <div
            v-if="question.explain"
            class="transcriptGroup"
          >
            <div
              class="transcriptGroup_title"
              :class="transcriptShow ? 'actived' : ''"
            >
              <span @click="transcriptShow =! transcriptShow">Giải thích</span>
            </div>
            <div
              v-if="transcriptShow"
              class="transcriptGroup_body"
            >
              <p v-html="question.explain" />
            </div>
          </div>
        </div>
      </div>
      <p
        v-if="question.image"
        class="text-center"
      >
        <img
          :src="storageUrl + question.image.url"
          alt=""
        >
      </p>
      <p v-if="question.audio">
        <audio
          class="mt-2"
          controls
        >
          <source
            :src="storageUrl + question.audio.url"
            type="audio/ogg"
          >
        </audio>
      </p>
      <ul
        v-if="question.answers"
        class="answer_question list-unstyled"
      >
        <template v-for="(ans) in question.answers">
          <li
            :id="answers.includes(ans.id) && !ans.is_correct ? 'failse_question' : ''"
            class="answer_question__item"
            :class="ans.is_correct ? 'success_question' : ''"
          >
            <label class="label_custom">
              <span v-html="ans.name" />
              <input
                type="radio"
                :value="ans.id"
                :name="'answer' + question.id"
                :disabled="true"
                :checked="answers.includes(ans.id)"
              >
              <span class="checkmark" />
            </label>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  name: 'OnlyQuestion',
  components: {},
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      transcriptShow: false,
      type_step: 1,
      storageUrl: process.env.STORAGE_BASE_URL,
    }
  },
  methods: {
    checkCorrectQuestion() {
      let textCorrectQuestion = 'Bỏ qua'
      this.question.answers.map(ans => {
        if (this.answers.includes(ans.id)) {
          if (ans.is_correct) {
            textCorrectQuestion = 'Đúng'
          } else {
            textCorrectQuestion = 'Sai'
          }
        }
      })
      return textCorrectQuestion
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
